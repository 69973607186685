import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import InstagramEmbed from 'react-instagram-embed';

class HFDG2020 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
    }
  }

  render() {
    const siteTitle = 'hanlie claasen interiors'

    return (
      <Layout ishome={false}>
        <Helmet title={siteTitle} />
        <section id="aboutus" className="main style1">
          <div className="grid-wrapper" >

            <div className="col-12" >
              <header className="major">
                <h2>theme</h2>
              </header>
              <div >
                <InstagramEmbed
                  url='https://www.instagram.com/p/B88EKabpFAF'
                  maxWidth={640}
                  hideCaption={false}
                  containerTagName='div'
                  style={{display: 'flex',
                  justifyContent: 'center',}}
                  protocol=''
                  injectScript
                  onLoading={() => { }}
                  onSuccess={() => { }}
                  onAfterRender={() => { }}
                  onFailure={() => { }}
                />
              </div>
            </div>
          </div>
        </section>
        <section id="team" className="main style1">
          <div className="grid-wrapper">
            <div className="col-12">
              <header className="major">
                <h2>the team</h2>
              </header>
            </div>
            <div className="col-6">

            </div>
          </div>
        </section>


        <section id="projects" className="main style1 special">
          <div className="grid-wrapper">
            <div className="col-12">
              <header className="major">
                <h2>projects</h2>
              </header>

            </div>


          </div>
        </section>


      </Layout>
    )
  }
}

export default HFDG2020
